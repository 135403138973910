<template>
  <tableHeader
    :result="result"
    :search="search"
    :currentTable="currentTable"
    :quickFilter="quickFilter"
    :tableDataSet="tableDataSet"
  ></tableHeader>
</template>
<script>
import tableHeader from "@/commonComponents/tableHeader.vue";

export default {
  components: {
    tableHeader,
  },

  props: {
    result: Object,
    search: String,
    currentTable: Object,
    quickFilter: { type: Boolean, default: false },
    tableDataSet: Object,
  },
};
</script>